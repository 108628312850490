<template>
  <div>
    <div class="card p-3 mb-3 shadow-sm">
      <div class="row justify-content-between">
        <div class="col-auto">
          <h5 class="e-text-red mb-0">
            Corrective Action Report
          </h5>
        </div>
      </div>
      <div class="card p-3 mb-3 shadow-sm text-center" v-if="companyNonConformities[$route.params.type] === null">
        No Data
      </div>
      <template v-else>
        <template v-for="(ncr, index) in companyNonConformities[$route.params.type]">
          <div class="p-3 card rounded-0 shadow-sm cursor-pointer no-select mt-2" @click="toggleNcrArrows(index)">
            <div class="row justify-content-between">
              <div class="pl-3 font-weight-bold">
                <span class="nc_title">NC {{ index + 1 }} : <i @click.stop="viewNonConformity(ncr.id)" class="nc_oe_title">{{ ncr.description | stripHtmlTags }} </i></span>
              </div>
              <div class="pr-3" v-if="ncr.objective_evidences.length > 0">
<!--                <font-awesome-icon v-if="ncrArrows[index] === true" icon="chevron-up" style="font-size: 20px"/>-->
<!--                <font-awesome-icon v-else icon="chevron-down" style="font-size: 20px"/>-->
                <font-awesome-icon icon="bars" style="font-size: 20px"/>
              </div>
            </div>
          </div>
          <template v-if="ncrArrows[index]">
            <div v-for="(oe, oe_index) in ncr.objective_evidences">
              <div class="card rounded-0 shadow-sm border-top-0">
                <div class="ml-3">
                  <div class="px-2 py-3 font-weight-bold cursor-pointer no-select" @click="toggleOeArrows(index, oe_index)">
                    <span class="">OE {{ oe_index + 1 }} : <i @click="viewObjectiveEvidence(ncr.id, oe.id)" class="nc_oe_title">{{ oe.description | stripHtmlTags }} </i></span>
                    <div style="float:right;" class="mr-2">
                      <font-awesome-icon v-if="oeArrows[index][oe_index] === true" icon="chevron-up"/>
                      <font-awesome-icon v-else icon="chevron-down"/>
                    </div>
                  </div>
                  <template v-if="oeArrows[index][oe_index] === true">
                    <table class="table table-sm table-bordered w-100">
                      <tr>
                        <th class="bg-light align-middle" width="10">
                          <p style="transform: rotate(270deg)">BEFORE</p>
                        </th>
                        <td>
                          <div style="max-height: 60vh; overflow-y: auto">
                            <template v-for="(image, index) in oe.before">
                              <div style="float: left" data-v-71fad98a="" class="col-md-6 col-sm-12 mb-1 justify-content-center align-content-center cursor-pointer p-0"
                                   @click="viewImage(index, oe.before, {
                             'path'      : 'base64',
                             'caption'   : 'name'
                       })">
                                <div class="p-2">
                                  <div data-v-16e2512b="" data-v-71fad98a="" id="ia-attachment-card" class="card p-4">
                                    <div :style="{ minWidth: '50%', backgroundImage: 'url(' + image.base64 + ')', width: 'auto', height: '230.5px' }" class="background-contain">
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </template>
                          </div>
                        </td>
                        <th class="bg-light align-middle" width="10">
                          <p style="transform: rotate(270deg)">AFTER</p>
                        </th>
                        <td>
                          <div style="max-height: 60vh; overflow-y: auto">
                            <template v-for="(image, index) in oe.after">
                              <div style="float: left" data-v-71fad98a="" class="col-md-6 col-sm-12 mb-1 justify-content-center align-content-center cursor-pointer p-0"
                                   @click="viewImage(index, oe.after, {
                             'path'      : 'base64',
                             'caption'   : 'name'
                       })">
                                <div class="p-2">
                                  <div data-v-16e2512b="" data-v-71fad98a="" id="ia-attachment-card" class="card p-4">
                                    <div :style="{ minWidth: '50%', backgroundImage: 'url(' + image.base64 + ')', width: 'auto', height: '230.5px' }" class="background-contain">
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </template>
                          </div>
                        </td>
                      </tr>
                    </table>
                    <div class="pl-3 mb-3 w-100">
                        <i>
                          Rectified Date: {{ oe.rectified_date.human ?? ''}} - {{  oe.rectified_comment ?? '(with any comment)' | stripHtmlTags }}
                        </i>
                    </div>
                  </template>
                </div>
              </div>
            </div>



          </template>





        </template>
      </template>

    </div>
    <image-slider
      :selected-image-index="selectedImageIndex"
      :images="sliderImages"
      :visible="showSlider"
      @hideSlider="resetSlider()"
    >
    </image-slider>
  </div>

</template>

<script>
import {mapGetters} from "vuex";
import ImageSlider from "@/components/common/ImageSlider.vue";
import ImageSliderMixin from "@/mixins/ImageSliderMixin";

export default {
  name: "CompanyIaCorrectiveActionReport",
  data() {
    return {
      ncrArrows : [],
      oeArrows : []
    }
  },
  methods : {
    async viewNonConformity(ncrId) {
      await this.$router.push(
        {
          name: 'CompanyNcrMainDetails',
          params: {
            id : this.$route.params.id,
            vessel_id : this.$route.params.vessel_id,
            type : this.$route.params.type,
            ncr_id : ncrId
          },
        },

      ).catch(() => {})
    },
    async viewObjectiveEvidence(ncrId, oeId) {
      await this.$router.push(
        {
          name: 'CompanyOEMainDetails',
          params: {
            id : this.$route.params.id,
            vessel_id : this.$route.params.vessel_id,
            type : this.$route.params.type,
            ncr_id : ncrId,
            oe_id : oeId,
          },
        },
      ).catch(() => {})
    },
    toggleNcrArrows(index) {
      this.$set(this.ncrArrows, index, !this.ncrArrows[index]);
    },
    toggleOeArrows(index, oeIndex) {
      this.$set(this.oeArrows[index], oeIndex, !this.oeArrows[index][oeIndex]);
    }
  },
  components: {ImageSlider},
  mixins : [ImageSliderMixin],
  computed : {
    ...mapGetters([
      'companyNonConformities',
    ])
  },
  filters : {
    stripHtmlTags(html) {
      const div = document.createElement('div');
      div.innerHTML = html;
      return div.textContent || div.innerText || '';
    }
  },
  created() {
    const ncr = this.companyNonConformities[this.$route.params.type];
    if (ncr !== null) {
      this.ncrArrows = new Array(ncr.length).fill(false);
      for (let counter = 0; counter < ncr.length; counter++) {
        const objectiveEvidenceLength = ncr[counter].objective_evidences.length;
        this.oeArrows.push(
          new Array(objectiveEvidenceLength).fill(false)
        )
      }
    }
  }
}
</script>

<style scoped>
.nc_oe_title:hover {
  color: #d3313c !important;
  cursor: pointer;
  user-select: none;
}

.nc_title {
  font-size: 16px;
}

.oe_title {
  font-size: 15px;
}

#ia-attachment-card:hover {
  background-color: #dae8f4;
  transition: 0.5s;
}
</style>
